import React from "react"
import { Link, graphql } from "gatsby"

import Header from "../components/Header"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import "prismjs/themes/prism-tomorrow.css"

// TODO: style cats, only show latest 20 posts on homepage, check SEO stuff, bit of general styling?, start another post?
// TODO: styling homepage like https://www.youtube.com/watch?v=8BzV5_gQjms&ab_channel=JenniferCreativeStudio
class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="A software engineering blog: JavaScript, TypeScript, React, React Native, Node, C#, Unity and more" />
        <Bio />

        <Header />

        <div className="mt-16 md:mt-28">
          <h2 className="mb-10">Latest posts</h2>
          {posts.slice(0, 10).map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div className="mb-10" key={node.fields.slug}>
                <h3 className="mb-2">
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small className="block mb-2 text-sm font-bodythin">
                  {node.frontmatter.date}
                </small>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { glob: "**/blog/*" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
